<template>
  <div class="container withTop">
    <section class="contents cultureinfo">
      <ul class="infoList">
        <router-link to @click.native="handleMove('토마토')" tag="li">
          <h3>토마토</h3>
          <h4>작물재배정보&#183;병/해충피해</h4>
        </router-link>
        <router-link to @click.native="handleMove('사과')" tag="li">
          <h3>사과</h3>
          <h4>작물재배정보&#183;병/해충피해</h4>
        </router-link>
        <router-link to @click.native="handleMove('딸기')" tag="li">
          <h3>딸기</h3>
          <h4>작물재배정보&#183;병/해충피해</h4>
        </router-link>
        <router-link to @click.native="handleMove('상추')" tag="li">
          <h3>상추</h3>
          <h4>작물재배정보&#183;병/해충피해</h4>
        </router-link>
        <router-link to @click.native="handleMove('레드향')" tag="li">
          <h3>레드향</h3>
          <h4>작물재배정보&#183;병/해충피해</h4>
        </router-link>
        <router-link to @click.native="handleMove('고추')" tag="li">
          <h3>고추</h3>
          <h4>작물재배정보&#183;병/해충피해</h4>
        </router-link>
        <router-link to @click.native="handleMove('포도')" tag="li">
          <h3>포도</h3>
          <h4>작물재배정보&#183;병/해충피해</h4>
        </router-link>
      </ul>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", 2);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "작물재배정보");
  },
  methods: {
    handleMove(type) {
      this.$router.push({ name: "cultureInfoList", query: { category: type } });
    },
  },
};
</script>
